import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Import your CSS styles

function InitializeText() {
  const [text, setText] = useState('');
  const navigate = useNavigate();

  const handleSetText = async () => {
    try {
      const res = await fetch('https://chat-api.virapayesh.ir/set_text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });
      if (res.ok) {
        alert('متن با موفقیت تنظیم شد!');
        navigate('/chat'); // Redirect to the chatbot page after setting the text
      }
    } catch (error) {
      console.error('Error setting text:', error);
    }
  };

  return (
    <div className="chat-container">
      <h3>تنظیم متن</h3>
      <div className="chat-window">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="متن خود را وارد کنید..."
          rows="5"
          style={{ width: '100%', padding: '10px', fontSize: '16px' }}
        />
      </div>
      <div className="input-container">
        <button onClick={handleSetText}>تنظیم متن</button>
      </div>
    </div>
  );
}

export default InitializeText;
