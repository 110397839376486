import React, { useState } from 'react';
import './App.css'; // Import your CSS styles

function Chatbot() {
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);

  const askQuestion = async () => {
    if (!question) return;

    // Add user's question to the chat history first
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { sender: 'user', message: question }
    ]);

    try {
      const res = await fetch('https://chat-api.virapayesh.ir/ask_question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question }),
      });
      const data = await res.json();

      // After getting the response from the bot, update the chat history with the bot's answer
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: 'bot', message: data.answer }
      ]);

      setQuestion('');  // Clear the input after asking a question
    } catch (error) {
      console.error('Error asking question:', error);
    }
  };

  return (
    <div className="chat-container">
      <h3>هاویر بات: ربات پرسش و پاسخ نهضت سواد آموزی استان قم </h3>
      <div className="chat-window">
        <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div key={index} className={`chat-bubble ${chat.sender === 'user' ? 'user-message' : 'bot-message'}`}>
              {chat.message}
            </div>
          ))}
        </div>
      </div>
      <div className="input-container">
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="سوال خود را بنویسید..."
          onKeyPress={(e) => e.key === 'Enter' && askQuestion()}  // Submit on Enter key
        />
        <button onClick={askQuestion}>ارسال سوال</button>
      </div>
    </div>
  );
}

export default Chatbot;
